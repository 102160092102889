@import 'shared.scss';

#root {
  .PageOverlay {
    @include z-index(page-overlay);
  }

  .header__top {
    position: fixed;
    top: 0;
    // opacity: 0;
    background-color: rgba($black, 0);
    height: $navbar-height-mobile;
    padding: 0 var(--space-md);

    @include z-index(main-nav);

    @include media('>=medium') {
      padding: 0 calc(var(--space-lg) * 1.1);
      height: $navbar-height-tablet;
    }

    @include media('>=large') {
      padding: 0 var(--space-lg);
      height: $navbar-height-desktop;
    }

    @include media('>=xlarge') {
      padding: 0 var(--space-xl);
      height: $navbar-height-desktop;
    }

    &__content {
      >a {
        opacity: 0;
      }

      .nav-logo {
        // opacity: 0;
        display: block;
        width: px-to-rem(120);

        // transition: opacity 0.3s ease;
        @include media('>=medium') {
          width: px-to-rem(140);
        }

        @include media('>=large') {
          width: px-to-rem(160);
        }

        &--visible {
          opacity: 1;
        }

        body.landing &,
        body.preloader & {
          display: none;
        }
      }

      .nav-item {
        a {
          color: $light;
          font-size: var(--text-md);
        }
      }
    }
  }

  .PageOverlay {
    @include z-index(page-overlay);
    background: rgba($black, 0);
  }

  .HamburgerMenu {
    top: 0;
    left: 0;
    right: auto;
    opacity: 0;
    @include z-index(sidebar);
    height: 100%;
    border-left: none;
    background-color: darken($grey, 2%);
    text-align: center;
    padding-top: calc(var(--space-xxxl) * 1.1);
    transition: opacity 0.8s 0.8s, transform 0s 1.6s;

    @include media('>=medium') {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      padding: 0 calc(var(--space-xxl) * 0.9) 15vh;
    }

    @include media('>=large') {
      padding: 0 var(--space-xxl) 20vh;
    }

    @include media('>=1400px') {
      padding: 0 var(--space-xxxl) 20vh;
    }

    .nav-list {
      text-align: center;

      @include media('>=medium') {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
      }

      .nav-item {
        margin-bottom: 5vh;
        opacity: 0;
        transform: translatey(-10%);
        // transition: opacity 0.6s, transform 0.6s;
        transition: opacity 0.4s, transform 0.4s;

        // @include media('>=medium') {
        //   margin-bottom: 5vh;
        // }
        @include media('>=large') {
          margin-bottom: 5vh;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            transition-delay: 50ms * $i + 200ms;
          }
        }

        a {
          font-family: var(--font-primary);
          font-size: var(--text-xl);
          color: $light;
          transition: color 0.6s ease;
          font-weight: 700;
          letter-spacing: 2px;

          @include media('>=medium') {
            font-size: var(--text-xxl);
            letter-spacing: 0;
          }

          @include media('>=large') {
            font-size: var(--text-xxxl);
          }

          @include media('>=1600px') {
            font-size: calc(var(--text-xxxl) * 1.1);
          }

          &.active {
            text-decoration: none;
            color: $white;
          }

          &:hover {
            text-decoration: none;
            color: $white;
          }
        }
      }
    }

    &.open {
      opacity: 1;
      transition: opacity 0.6s, transform 0s;

      .nav-item {
        opacity: 1;
        transform: translatey(0);
        transition: opacity 1.2s, transform 1.2s;
      }
    }

    .HamburgerMenu__info {
      display: none;
      color: $white;
      text-align: right;

      @include media('>=medium') {
        display: flex;
        flex-direction: column;
        width: 45%;
        justify-content: flex-end;
      }

      &__contact {
        padding-bottom: var(--space-sm);
        opacity: 0;
        transform: translatey(-10%);
        transition: opacity 0.6s, transform 0.6s;

        @include media('>=large') {
          padding-bottom: var(--space-xs);
        }

        @for $i from 1 through 3 {
          &:nth-child(#{$i}) {
            transition-delay: 100ms * $i + 300ms;
          }
        }

        &:last-child {
          padding-bottom: 0;
        }

        svg {
          width: px-to-rem(18);
          fill: $white;
          display: inline;
          margin-right: px-to-rem(10);
          position: relative;
          top: px-to-rem(5);
        }

        &__link {
          text-decoration: none;
          display: inline;
          color: $white;
          font-family: var(--font-primary);
          font-size: var(--text-md);
          transition: color 0.6s ease;

          &:hover {
            text-decoration: none;
            color: $light;
          }
        }
      }
    }

    &.open {
      .HamburgerMenu__info__contact {
        opacity: 1;
        transform: translatey(0);
      }
    }
  }

  .HamburgerButton {
    position: relative;
    width: px-to-rem(50);
    height: px-to-rem(50);
    @include flex-center;
    pointer-events: all;

    @include media('>=medium') {
      width: px-to-rem(64);
      height: px-to-rem(64);
    }

    body.preloader & {
      pointer-events: none;
    }

    svg {
      width: px-to-rem(50);
      height: px-to-rem(50);
      position: absolute;
      top: 0;
      left: 0;
      fill: $grey-darker;
      transition: fill 0.3s ease, transform 0.3s ease;

      @include media('>=medium') {
        width: px-to-rem(64);
        height: px-to-rem(64);
      }
    }

    .bars-container {
      position: relative;
      left: 0.2rem;
      top: 0.1rem;
      width: 1.8rem;
      height: 0.8rem;

      @include media('>=medium') {
        left: 0.2rem;
        top: 0.1rem;
        width: 2.2rem;
        height: 0.9rem;
      }

      .bar {
        background: $white;
        position: relative;
        height: 3px;
        transition: transform 0.3s ease;

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(1) {
          left: -0.3rem;
        }
      }
    }

    &.close {
      .bars-container {
        left: 0.25rem;
        width: 2rem;
        height: 1.6rem;

        .bar {
          &:nth-child(1) {
            left: 0;
          }
        }
      }
    }

    &:hover {
      svg {
        fill: darken($grey-darker, 2%);
        transform: scale(1.05);
      }
    }

    &:not(.close):hover {
      .bars-container {
        .bar {
          &:nth-child(3) {
            transform: translateX(-15%);
          }

          &:nth-child(1) {
            transform: translateX(15%);
          }
        }
      }
    }
  }

  .Footer {
    opacity: 0;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    @include z-index(footer);
    padding: var(--space-md) 0;
    text-align: center;
    color: $light;
    background-color: $grey-darker;
    transition: transform 0.6s ease 1.5s;

    body.landing &,
    body.preloader & {
      // transform: translateY(100%);
      display: none;
    }

    @include media('>=medium') {
      padding: var(--space-lg) var(--space-md) var(--space-sm);
    }

    @include media('>=large') {
      padding: var(--space-lg) var(--space-md) var(--space-sm);
      display: block;
      position: absolute;
      bottom: 0;
    }

    .footer-nav {
      color: $light;
      margin-bottom: var(--space-xs);

      .nav-list {
        .nav-item {
          margin-right: var(--space-xxs);

          @include media('>=large') {
            margin-right: var(--space-md);
          }

          &:last-child {
            margin-right: 0;
          }
        }

        a {
          color: $light;
          font-family: var(--font-primary);
          font-size: calc(var(--text-xxs) * 0.9);
          text-decoration: none;
          font-weight: 700;
          transition: color 0.3s ease;

          &:hover {
            color: $white;
          }

          @include media('>=large') {
            font-size: calc(var(--text-xs) * 0.9);
          }
        }
      }
    }

    .footer-copyright {
      color: $light;
      font-family: var(--font-primary);
      font-size: var(--text-xxs);
      font-weight: 300;
    }
  }

  .RotateScreen {
    @include z-index(rotate);
  }
}

// .is-virtual-scroll {
//   overflow: hidden !important;
// }

.vs-scrollbar {
  z-index: 100;

  .vs-scrolldrag {
    background: $gold !important;
  }
}

.vs-scrollbar.vs-vertical {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 7px !important;
  height: 100% !important;
  transform: none !important;
}

.ReactModalPortal {
  position: fixed;
  z-index: 999 !important;

  .ReactModal__Overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba($grey-darker, 0.95) !important;
    padding-top: px-to-rem(60);
    opacity: 0;
    transition: opacity 1s ease;

    &--after-open {
      opacity: 1;
    }
  }

  .ReactModal__Content {
    border: 0 none;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: px-to-rem(15);

    @include media('>=large') {
      padding: px-to-rem(20);
      width: 50%;
      height: 50%;
    }

    &:focus-visible {
      outline: 0 none !important;
    }


    img {
      width: 100%;
      height: auto;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;

      svg {
        fill: $white;
        width: px-to-rem(30);

        @include media('>=large') {
          width: px-to-rem(40);
        }

      }
    }

  }
}

.modal-video {
  background-color: rgba($grey-darker, 0.9) !important;
  z-index: 999 !important;
  padding: 0 var(--space-xs);

  &:focus {
    outline: none !important;
  }

  @include media('>=large') {
    height: 100vh !important;
  }

  .modal-video-close-btn {
    right: 0 !important;
  }
}

.cookieConsent {
  background: rgba($grey-darker, 0.92);
  color: $white;
  position: fixed;
  text-align: center;
  padding: var(--space-md);
  bottom: px-to-rem(10) !important;
  right: px-to-rem(10);
  height: px-to-rem(145);
  width: 72%;
  z-index: 200;

  @include media('>=medium') {
    width: px-to-rem(340);
    height: px-to-rem(150);
  }

  @include media('>=large') {
    width: px-to-rem(400);
    height: px-to-rem(170);
    bottom: px-to-rem(20) !important;
    right: px-to-rem(20);
  }

  >div {
    font-size: calc(var(--text-sm) * 1.2);
    margin-bottom: px-to-rem(20);
    // @include media('>=large') {
    //   font-size: var(--text-sm);
    // }
  }

  button {
    color: $white;
    padding: px-to-rem(10) px-to-rem(20);
    background-color: darken($gold, 8%);
    text-transform: uppercase;
    font-size: var(--text-sm);
    font-weight: 700 !important;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $gold;
      transition: background-color 0.3s ease;
    }
  }
}