:root {
  --btn-font-size: 0.75em;
  --btn-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-md: calc(var(--btn-font-size) + 0.2em);
  --btn-lg: calc(var(--btn-font-size) + 0.4em);
  --btn-radius: var(--radius); }

@media (min-width: 768px) {
  :root {
    --btn-font-size: 0.7em; } }

@media (min-width: 1100px) {
  :root {
    --btn-font-size: 0.65em; } }

button,
input[type='reset'],
input[type='submit'],
input[type='button'] {
  background: none;
  border: 0;
  cursor: pointer;
  overflow: visible;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-primary);
  width: auto;
  -webkit-appearance: none;
  background-clip: padding-box;
  border-radius: 0;
  box-sizing: border-box;
  line-height: normal !important;
  font-weight: bold; }
  button:active, button:focus,
  input[type='reset']:active,
  input[type='reset']:focus,
  input[type='submit']:active,
  input[type='submit']:focus,
  input[type='button']:active,
  input[type='button']:focus {
    text-decoration: none; }

.btn {
  display: inline-flex;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  font-weight: 500;
  padding: 0.4375rem 0.625rem;
  border-radius: var(--btn-radius);
  font-size: var(--btn-font-size);
  color: #fff;
  border: 1px solid #fff;
  transition: 0.2s;
  margin: var(--space-xs) 0 var(--space-md);
  font-family: var(--font-secondary);
  transition: all 0.3s ease; }
  @media (min-width: 320px) {
    .btn {
      padding: 0.5rem 0.75rem; } }
  @media (min-width: 414px) {
    .btn {
      padding: 0.625rem 0.875rem; } }
  @media (min-width: 768px) {
    .btn {
      padding: 0.75rem 1.125rem; } }
  @media (min-width: 1100px) {
    .btn {
      padding: 0.9375rem 1.5625rem; } }
  .btn:hover {
    border: 1px solid #fff;
    color: #fff; }
  .btn:active {
    transition: none; }
  .btn .icon {
    /* icon inherits color of button label */
    color: inherit;
    flex-shrink: 0; }

/* themes */
.btn--primary {
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-label);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .btn--primary:visited {
    color: var(--btn-primary-label); }
  .btn--primary:hover {
    background-color: var(--btn-primary-hover); }
  .btn--primary:active {
    background-color: var(--btn-primary-active); }

.btn--secondary {
  background-color: var(--btn-secondary-bg);
  color: var(--btn-secondary-label); }
  .btn--secondary:visited {
    color: var(--btn-secondary-label); }
  .btn--secondary:active {
    background-color: var(--btn-secondary-active); }

.btn--accent {
  background-color: var(--btn-accent-bg);
  color: var(--btn-accent-label);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .btn--accent:visited {
    color: var(--btn-accent-label); }
  .btn--accent:hover {
    background-color: var(--btn-accent-hover); }
  .btn--accent:active {
    background-color: var(--btn-accent-active); }

/* feedback */
.btn[disabled] {
  cursor: not-allowed;
  background-color: var(--btn-disabled-bg);
  color: var(--btn-disabled-label);
  box-shadow: none;
  opacity: 0.6; }
  .btn[disabled]:visited {
    color: var(--btn-disabled-label); }

/* button size */
.btn--sm {
  font-size: var(--btn-sm); }

.btn--md {
  font-size: var(--btn-md); }

.btn--lg {
  font-size: var(--btn-lg); }

.btn--full-width {
  display: flex;
  width: 100%;
  justify-content: center; }

/* buttons group */
.btns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(-1 * var(--space-xs)); }
  .btns > * {
    margin-right: var(--space-xs);
    margin-bottom: var(--space-xs); }
    .btns > *:last-of-type {
      margin-right: 0; }

:root {
  --color-blue: #1E90FF;
  --color-green: #32CD32;
  --color-red: #FF0000;
  --main-white: white;
  --main-black: #000000;
  --main-gray: #474747;
  --grey-9: #232323;
  --grey-8: #353535;
  --grey-7: #474747;
  --grey-6: #757575;
  --grey-5: #a3a3a3;
  --grey-4: #bfbfbf;
  --grey-3: #dadada;
  --grey-2: #ededed;
  --grey-1: #f6f6f6; }

.color-text-green {
  color: var(--color-green); }

.color-text-blue {
  color: var(--color-blue); }

:root {
  --container-width-xs: 37.5rem;
  --container-width-sm: 45rem;
  --container-width-md: 64rem;
  --container-width-lg: 80rem;
  --container-width-xl: 90rem; }

.container {
  width: calc(100% - 2 * var(--component-padding));
  margin-left: auto;
  margin-right: auto;
  max-width: 87.5rem; }

:root {
  --space-unit: 1.5em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-xxl); }
  @media (min-width: 320px) {
    :root {
      --space-unit: 1.25em;
      --component-padding: var(--space-sm); } }
  @media (min-width: 768px) {
    :root {
      --component-padding: var(--space-xl); } }
  @media (min-width: 1100px) {
    :root {
      --space-unit: 1.5em;
      --component-padding: var(--space-xxl); } }

:root {
  --font-primary: 'Cormorant Garamond', Georgia, Times, Times New Roman, serif;
  --font-secondary: 'Fira Sans', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  /* set base values */
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  /* type scale */
  --text-xxs: calc(var(--text-xs) / var(--text-scale-ratio));
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --body-line-height: 1.6;
  --heading-line-height: 1.3;
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1; }

body {
  font-size: 1em; }
  @media (min-width: 768px) {
    body {
      font-size: 1.05em; } }
  @media (min-width: 1100px) {
    body {
      font-size: 1.25em; } }
  @media (min-width: 1920px) {
    body {
      font-size: 1.3em; } }

h1,
h2,
h3,
h4 {
  font-weight: 500;
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxxs);
  line-height: var(--heading-line-height); }

p {
  line-height: var(--body-line-height); }

b,
strong {
  font-weight: bold; }

/* utility classes */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

[hidden] {
  display: none !important; }

.Pages {
  position: relative;
  text-align: center;
  z-index: 2;
  flex-grow: 1; }
  .Pages > section {
    width: 100%;
    opacity: 0; }
