$base-path: './../assets/fonts/';

/* cormorant-garamond-300normal - latin */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Cormorant Garamond Light'), local('CormorantGaramond-Light'),
    url('#{$base-path}cormorant-garamond_latin/cormorant-garamond-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('#{$base-path}cormorant-garamond_latin/cormorant-garamond-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cormorant-garamond-400normal - latin */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Cormorant Garamond Regular'), local('CormorantGaramond-Regular'),
    url('#{$base-path}cormorant-garamond_latin/cormorant-garamond-latin-400.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('#{$base-path}cormorant-garamond_latin/cormorant-garamond-latin-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* cormorant-garamond-700normal - latin */
@font-face {
  font-family: 'Cormorant Garamond';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Cormorant Garamond Bold'), local('CormorantGaramond-Bold'),
    url('#{$base-path}cormorant-garamond_latin/cormorant-garamond-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('#{$base-path}cormorant-garamond_latin/cormorant-garamond-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-300normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Fira Sans Light'), local('FiraSans-Light'),
    url('#{$base-path}/fira-sans_latin/fira-sans-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$base-path}/fira-sans_latin/fira-sans-latin-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-400normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Fira Sans Regular'), local('FiraSans-Regular'),
    url('#{$base-path}/fira-sans_latin/fira-sans-latin-400.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$base-path}/fira-sans_latin/fira-sans-latin-400.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* fira-sans-700normal - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Fira Sans Bold'), local('FiraSans-Bold'),
    url('#{$base-path}/fira-sans_latin/fira-sans-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('#{$base-path}/fira-sans_latin/fira-sans-latin-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
