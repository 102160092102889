:root {
  --btn-font-size: 0.75em;
  --btn-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-md: calc(var(--btn-font-size) + 0.2em);
  --btn-lg: calc(var(--btn-font-size) + 0.4em);

  --btn-radius: var(--radius);
}

@include media('>=medium') {
  :root {
    --btn-font-size: 0.7em;
  }
}
@include media('>=large') {
  :root {
    --btn-font-size: 0.65em;
  }
}

button,
input[type='reset'],
input[type='submit'],
input[type='button'] {
  background: none;
  border: 0;
  cursor: pointer;
  overflow: visible;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  font-family: var(--font-primary);
  width: auto;
  -webkit-appearance: none;
  background-clip: padding-box;
  border-radius: 0;
  box-sizing: border-box;
  line-height: normal !important;
  font-weight: bold;
  &:active,
  &:focus {
    text-decoration: none;
  } // active
} // button

.btn {
  display: inline-flex;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  // letter-spacing: 2px;
  font-weight: 500;
  padding: px-to-rem(7) px-to-rem(10);
  border-radius: var(--btn-radius);
  font-size: var(--btn-font-size);
  color: $white;
  border: 1px solid $white;
  transition: 0.2s;
  margin: var(--space-xs) 0 var(--space-md);
  font-family: var(--font-secondary);
  transition: all 0.3s ease;
  @include media('>=xsmall') {
    padding: px-to-rem(8) px-to-rem(12);
  }
  @include media('>=small') {
    padding: px-to-rem(10) px-to-rem(14);
  }
  @include media('>=medium') {
    padding: px-to-rem(12) px-to-rem(18);
  }
  @include media('>=large') {
    padding: px-to-rem(15) px-to-rem(25);
  }

  &:hover {
    border: 1px solid $white;
    color: $white;
  }
  &:active {
    transition: none;
  }

  .icon {
    /* icon inherits color of button label */
    color: inherit;
    flex-shrink: 0;
  }
}

/* themes */
.btn--primary {
  // main button
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-label);
  @include fontSmooth;

  &:visited {
    color: var(--btn-primary-label);
  }

  &:hover {
    background-color: var(--btn-primary-hover);
  }

  &:active {
    background-color: var(--btn-primary-active);
  }
}

.btn--secondary {
  // subtle version, used for secondary actions or in combo with primary/accent button
  background-color: var(--btn-secondary-bg);
  color: var(--btn-secondary-label);

  &:visited {
    color: var(--btn-secondary-label);
  }

  &:active {
    background-color: var(--btn-secondary-active);
  }
}

.btn--accent {
  // used to draw special attention to the button (e.g. destructive actions)
  background-color: var(--btn-accent-bg);
  color: var(--btn-accent-label);
  @include fontSmooth;

  &:visited {
    color: var(--btn-accent-label);
  }

  &:hover {
    background-color: var(--btn-accent-hover);
  }

  &:active {
    background-color: var(--btn-accent-active);
  }
}

/* feedback */
.btn[disabled] {
  cursor: not-allowed;
  background-color: var(--btn-disabled-bg);
  color: var(--btn-disabled-label);
  box-shadow: none;
  opacity: 0.6;

  &:visited {
    color: var(--btn-disabled-label);
  }
}

/* button size */
.btn--sm {
  font-size: var(--btn-sm);
}

.btn--md {
  font-size: var(--btn-md);
}

.btn--lg {
  font-size: var(--btn-lg);
}

.btn--full-width {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* buttons group */
.btns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(-1 * var(--space-xs));

  > * {
    margin-right: var(--space-xs);
    margin-bottom: var(--space-xs);

    &:last-of-type {
      margin-right: 0;
    }
  }
}
