$main-top-nav-height: 6rem !default;
$main-top-nav-padding: 0 4rem !default;
$main-top-nav-bg-color: #eee !default;
$main-top-nav-logo-width: 4rem !default;
$main-top-nav-links-margin: 2rem !default;
$main-top-nav-link-font-size: 2rem !default;
$main-top-nav-idle-link-color: rgba(#000, 0.6) !default;
$main-top-nav-hover-link-color: rgba(#000, 1) !default;
$main-top-nav-active-link-color: rgba(#000, 1) !default;
$main-top-nav-idle-link-decoration: none !default;
$main-top-nav-hover-link-decoration: none !default;
$main-top-nav-active-link-decoration: underline !default;

.header__top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: $main-top-nav-padding;
  height: $main-top-nav-height;
  background: $main-top-nav-bg-color;
  box-sizing: border-box;
  pointer-events: none;

  .only-aria-visible {
    clip-path: inset(100%);
    clip: rect(1px 1px 1px 1px);
    /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .nav-logo {
      width: $main-top-nav-logo-width;
      pointer-events: all;
    }
  }
}